<template>
  <v-container>
    <v-sheet class="mx-auto" width="80%">
      <div id="container" class="text-caption">
        <div>
          <p v-html="$options.filters.md(data.policy)" />
        </div>
      </div>
      <!-- <div id="container" class="text-caption">
        <div :style="{ width: $vuetify.breakpoint.mdAndUp ? '70%' : '90%' }">
          <p v-html="$options.filters.md(data.policy)" />
        </div>

        <div v-if="$vuetify.breakpoint.mdAndUp" style="width: 25%">
          <p class="nav" v-html="$options.filters.md(data.index)" />
        </div>
      </div> -->
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},

  computed: {},

  data: () => ({
    initializing: true,
    data: {
      index: "*index*",
      policy: "*policy*",
    },
  }),
  methods: {
    async onLoadAsync() {
      const data = await this.$application.app.loadPolicy();
      this.data.index = data.index;
      this.data.policy = data.body;
    },
  },
  async mounted() {
    await this.onLoadAsync();
  },
};
</script>
<style scoped>
/*ナビゲーションの固定*/

.nav {
  /*stickyで固定*/
  position: -webkit-sticky; /*Safari*/
  position: sticky;
  /*固定したい位置*/
  top: 100px;
}

/*レイアウトのためのCSS*/
#container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/*768px以下の見え方*/

@media screen and (max-width: 768px) {
  #main-area {
    width: 100%;
  }

  #sub-area {
    width: 100%;
    padding: 20px;
  }

  nav {
    position: relative; /*stickyからrelativeに戻す*/
    top: 0;
  }
}
</style>
